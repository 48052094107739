import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Container, Typography, Card, CardContent} from '@mui/material';
import TransactionStatus from './TransactionStatus';
import Web3 from "web3";


export default function ImageChoices({ folderData, walletAddress, allowLimit }) {
  const images = Object.values(folderData)[0];
  const [addressMintedBalance, setAddressMintedBalance] = React.useState(null);

  const contractABI = require('./ABI.json')
  const web3 = new Web3(window.ethereum);
  const contractAddress = "0xaa2eCb6b5d478C65737DCBa7689bcBe90d21A653"
  const nftContract = new web3.eth.Contract(contractABI, contractAddress);
  
  
  const [backgroundTargetImg, setBackgroundTargetImg] = React.useState(
    images.length > 0 ? images[0].url : null
  );
  const [soulTargetImg, setSoulTargetImg] = React.useState('')
  const [meta, setMeta] = React.useState({
      Background: '',
      Soul: '',
  })

  

  const setMataData = (target) => {

    if (target.includes('BACKGROUNDS')) {
      const pattern = /\/([^/]+\/[^/]+)\.png/;
      const match = target.match(pattern);
      const extractedString = match[1];
      const decodedBackrgoundString = decodeURIComponent(extractedString);
      setMeta((prevState) => ({ ...prevState, Background: decodedBackrgoundString }));
      setBackgroundTargetImg(target)
    } else if (target.includes('SKINS')) {
      const pattern = /\/([^/]+\/[^/]+)\.png/;
      const match = target.match(pattern);
      const extractedString = match[1];
      const decodedSoulString = decodeURIComponent(extractedString);
      setMeta((prevState) => ({ ...prevState, Soul: decodedSoulString }));
      setSoulTargetImg(target)
    }
  } 
  
    const checkLimit = async () => {
      const balance = await nftContract.methods.addressMintedBalance(walletAddress).call({ from: walletAddress });
      setAddressMintedBalance(balance)
    }; 
  React.useEffect(() => {
      checkLimit();
      console.log(addressMintedBalance);
  },[meta])

  return (
    <>
      <Container style={{ width: '600px', justifyContent: 'center', flex: 'center', padding: '1rem' }}>
      <Container sx={{ border: '1px solid black', borderRadius: '8px', padding: '1rem' }}>
        <Typography variant="h3" gutterBottom sx={{fontWeight: 'bold', fontSize:"32px" }}>
          Trait Versions 
        </Typography>
      </Container>
        <ImageList sx={{ width: 400, height: 900, borderRadius: '8px' }} cols={1}>
          {images.map((item) => (
            <ImageListItem key={item.fileName}>
              <img
                src={item.url}
                alt={item.title}
                loading="lazy"
                value={item.fileName}
                onClick={() => setMataData(item.url)}
                />
            </ImageListItem>
          ))}
        </ImageList>
      </Container>
      <Container style={{  justifyContent: 'center', alignItems: 'center', padding: '1rem'}}> 
        <Container sx={{ border: '1px solid black', borderRadius: '8px', padding: '1rem' }}>
          <Typography variant="h3" gutterBottom sx={{fontWeight: 'bold', fontSize:"32px" }}>
            Art Display 
          </Typography>
        </Container>
        <Container sx={{padding: '0.5rem', alignContent: 'center', display: 'flex'}}>
         <Container> 
         <Typography variant="h4" gutterBottom sx={{fontWeight: 'bold', fontSize:"26px" }}>
            How to mint a soul
          </Typography>
          <Typography variant="subtitle1" gutterBottom sx={{ fontSize:"20px" }}>
            Minting your favourite NFT is simple
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ fontSize:"20px"}}> 
            <li>Select a background</li>
            <li>Select a soul</li>
            <li>Click "Mint"</li>
          </Typography>
         </Container>
          <Container> 
          <Typography variant="h4" gutterBottom sx={{fontWeight: 'bold', fontSize:"26px" }}>
            Your Mint Limit 
          </Typography>
          <Typography variant="body1" gutterBottom sx={{ fontSize:"20px"}}> 
            <li>Your mint limit is {allowLimit.allowLimit}</li>
            <li>You have minted {addressMintedBalance}</li>
          </Typography>
          </Container>
        </Container>
        <Container style={{ height: '650px', display: 'flex', marginBottom: '1rem' }}>
          <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <img
              src={`${backgroundTargetImg}`}
              alt={"item.test"}
              loading="lazy"
              width='650'
              height='auto'
              style={{ position: 'absolute', zIndex: 1, borderRadius: '8px'}}
            />
            <img
              src={`${soulTargetImg}`}
              alt={"Select Soul"}
              loading="lazy"
              width='650'
              style={{ position: 'absolute', zIndex: 2 }}
            />
          </Container>
        </Container>
          <TransactionStatus walletAddress={walletAddress} meta={meta} allowLimit={allowLimit} addressMintedBalance={addressMintedBalance}/>
      </Container>
    </>
  );
}
