import React, { useState } from "react";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import logo from './Images/appBarLogo.png';
import Box from '@mui/material/Box';
import { Typography, Button } from '@mui/material';
import axios from "axios";
import FileTree from "./FileTree"
import { Container } from "@mui/system";
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';

const Navbar = () => {
    const [walletAddress, setWalletAddress] = useState("");
    const [isValid, setIsValid] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [allowLimit, setAllowLimit] = useState({})
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    
    // Function to check the validity of the wallet
    const checkWalletValidity = (walletAddress) => {
        const apiUrl = `https://m5cbvjq6l4.execute-api.us-east-1.amazonaws.com/v1/wallet_validation?wallet_id=${walletAddress}`;
      
        axios.get(apiUrl)
        .then(response => {
          if (response.data.wallet_address === walletAddress) {
            setIsValid(true);
            console.log(response.data);
            setAllowLimit(
              {
                "allowLimit": response.data.mint_limit, 
                "merkleProof": response.data.merkleProof
              }
            )
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            console.log("from state: ", walletAddress);
            setAlertSeverity('error');
            setAlertMessage("This wallet address is not on the allow list, try another wallet address and refresh the page.\nIf you think this wallet address should be on the allow list please contact us on Discord");
            setAlertOpen(true);
            setWalletAddress("");
            setIsValid(false);
            setIsConnected(false);
          } else {
            console.log(error.response);
            alert("Something went wrong, please contact us on Discord");
          }
        })
    }

    const toggleWalletConnection = () => {
        if (isConnected) {
          setWalletAddress("");
          setIsValid(false);
          setIsConnected(false);
        } else {
          // Check if Web3 is injected into the browser
          if (typeof window.ethereum !== "undefined") {
            // Request access to the user's wallet
            window.ethereum
              .request({ method: "eth_requestAccounts" })
              .then((accounts) => {
                // Set the user's wallet address
                setWalletAddress(accounts[0]);
                // Listen for changes to the user's wallet
                window.ethereum.on("accountsChanged", (accounts) => {
                  setWalletAddress(accounts[0]);
                  checkWalletValidity(accounts[0]);
                });
    
                // Check the validity of the wallet
                checkWalletValidity(accounts[0]);
                setIsConnected(true);
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            console.log("Web3 not detected");
            }
        }
    };
    return (
        <Box>
          <AppBar position="static" sx={{flexGrow: 1, backgroundColor:  'rgba(0%, 0%, 0%, 0)', display: 'flex'}}>
            <Toolbar>
              <Typography sx={{ display: 'flex',position: 'relative',flexGrow: 1,paddingTop: 2, paddingBottom: 1, margin: '0 auto'}}>
                <img src={logo} width="auto" height={150} alt="logo" />
              </Typography>
              <Button variant="contained" onClick={toggleWalletConnection} sx={{ backgroundColor: '#fff28c', color: 'black' }}>
                {isConnected
                ? `Wallet: ${String(walletAddress).substring(0, 6)}...${String(walletAddress).substring(38)}`
                : "Connect Wallet"}
              </Button>
            </Toolbar>
          </AppBar>
          <Container maxWidth='xl' sx={{ padding: '1rem' }}>
            {walletAddress &&  isValid && <FileTree walletAddress={walletAddress} isValid={isValid} allowLimit={allowLimit}/>}
          </Container>
          <Container maxWidth='md'>
          <Stack sx={{ width: '100%', display: 'flex', alignItems: 'center' }} spacing={2}>
              <Alert severity={alertSeverity} open={alertOpen}>
                {alertMessage}
              </Alert>
            </Stack>
          </Container>
        </Box>
      );
      
}

export default Navbar;