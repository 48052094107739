import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Navbar from './Navbar';

ReactDOM.render(
    <div>
      <Navbar/>
    </div>,

  document.getElementById('root')
);