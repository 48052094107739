import React from 'react';
import { Button, Box, CircularProgress } from "@mui/material";
import { Container } from '@mui/system';
import Web3 from "web3";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import axios from 'axios';

export default function TransactionStatus({ addressMintedBalance, allowLimit, walletAddress, meta, setMeta, setBackgroundTargetImg, setSoulTargetImg }) {
    
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [receipt, setReceipt] = React.useState(null);
    const [txHash, setTxHash] = React.useState(null);
    const [tokenID, setTokenID] = React.useState(null);
    const [duplicateStatus, setDuplicateStatus] = React.useState(null);
    const [openseaLink, setOpenseaLink] = React.useState(null);

    const contractABI = require('./ABI.json')
    const web3 = new Web3(window.ethereum);
    const contractAddress = "0xaa2eCb6b5d478C65737DCBa7689bcBe90d21A653"
    const nftContract = new web3.eth.Contract(contractABI, contractAddress);

    
    const handleMintClick = async () => {
        setIsModalOpen(true);
        setIsLoading(true);
        try {
            if (meta.Background !== '' && meta.Soul !== '' && addressMintedBalance !== allowLimit.allowLimit) {
                const response = await axios.put(
                    'https://m5cbvjq6l4.execute-api.us-east-1.amazonaws.com/v1/duplicate_check',
                {
                    "Background": meta.Background,
                    "Soul": meta.Soul
                }
                );
    
                if (response.data.statusCode === 200) {
                    console.log('Duplicate check: => ', response.data.statusCode);
                    setDuplicateStatus(false);
                    try {
                        const mintAmount = 1
                        // const merkleProof = ["0xa9117768cfc95bf87f5f5e15a8b7bf973782dae1e8058151a684bb312d628e89","0xee359235d791b6597f76dfab872983dfff9d60b63e24be7264a86a9343db36a9"];
                        
                        const merkleProof = JSON.parse(allowLimit.merkleProof);

                        console.log(merkleProof);
                        
                        const mintTx = await nftContract.methods.mint(mintAmount, 100, merkleProof)
                
                        .send({ from: walletAddress })
                            .on('transactionHash', function(hash){
                                console.log('Transaction hash:', hash);
                                setTxHash(hash)
                            })
                            .on('receipt', function(receipt){
                                console.log('Transaction receipt:', receipt);
                                setTokenID(receipt.events.Transfer.returnValues.tokenId)
                                setReceipt(receipt);
                                setIsLoading(false);
                            })
                            .on('error', function(error, receipt) {
                                console.error('Transaction error:', error);
                                console.error('Transaction receipt:', receipt);
                                setIsLoading(false);
                            });
                    } catch (error) {
                        console.log(error);
                    }
                } else if (response.data.statusCode === 400 ) {
                    setDuplicateStatus(true);
                } else {
                    throw new Error(`Request failed with status ${response.status}`);
                }
            }
        } catch (error) {
            console.error('Error duplicate check request:', error);
            throw error;
        }
    };
    
    const logRequest = async () => {
        try {
            if (!txHash) {
                throw new Error('Missing required parameter');
            }
            const response = await axios.post(
            'https://m5cbvjq6l4.execute-api.us-east-1.amazonaws.com/v1/log_mint_request',
            {
                tx_hash: txHash,
                status: 'minting',
                wallet_id: walletAddress,
                meta_data: meta
            }
            );

            if (response.status >= 200 && response.status < 300) {
                console.log('mint request sent');
                console.log('logged request ', response.data);
                return response.data;
            } else {
                throw new Error(`Request failed with status ${response.status}`);
            }
        } catch (error) {
                console.error('Error logging request:', error);
                throw error;
        }
        };

    React.useEffect(() => {
        if (receipt !== null) {
            console.log("tokenid = ",receipt.events.Transfer.returnValues.tokenId);
            createNFT()
            console.log("NFT creation request sent");
        }
    }, [receipt]);

    React.useEffect(() => {
        if (txHash !== null) {
            logRequest()
            console.log("send log request");
        }
    }, [txHash])
    
    const handleModalClose = (event, reason) => {
        // Only close the modal if it was not closed by a backdrop click or the escape key
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setIsModalOpen(false);
            setTxHash(null);
            setIsLoading(false);
            console.log(meta);
        }
        };
    
        const createNFT = async () => {
        try {
            if (receipt.status === true && tokenID !== null) {
            }
            const response = await axios.put(
            'https://m5cbvjq6l4.execute-api.us-east-1.amazonaws.com/v1/create_nft',
            {
                tx_hash: txHash,
                tokenId: `${tokenID}`
            }
            );

            if (response.status >= 200 && response.status < 300) {
                console.log('mint request sent');
                console.log('Created successfully: ', response.data);
                setOpenseaLink(response.data.imageLink)
                return response.data;
            } else {
                throw new Error(`Request failed with status ${response.status}`);
            }
        } catch (error) {
            console.error('Error logging request:', error);
            throw error;
        }
    }
    React.useEffect(() => {
        if (openseaLink !== null) {
            console.log(openseaLink);
        }
    }, [openseaLink]);
    return (
    <>
    <Button variant="contained" onClick={handleMintClick} disabled={meta.Background === '' || meta.Soul === '' || addressMintedBalance === allowLimit.allowLimit} sx={{ backgroundColor: '#fff28c', color: 'black' }}>
        Mint
    </Button>
    <Dialog open={isModalOpen} onClose={handleModalClose}>
        <DialogTitle>Transaction Status</DialogTitle>
        <DialogContent>
        <DialogContentText>
            {isLoading ? (
            <Box>
                {duplicateStatus === null ? (
                <>
                    <Typography>Checking for duplicate meta data...</Typography>
                    <CircularProgress />
                </>
                ) : duplicateStatus === true ? (
                <>
                    <Typography>
                        Sorry your Background and Soul selections seem to be a duplicate, an NFT with this metadata is already minted. Please try choosing another combination. 
                    </Typography>
                    <DialogActions>
                    <Button onClick={handleModalClose}>Close</Button>
                    </DialogActions>
                </>
                ) : duplicateStatus === false && txHash === null ? (
                <>
                    <Typography>
                    <li>
                        Metadata is unique, you're ready to mint. 
                    </li>
                    <li>
                        Please accept the transaction if you wish to continue the mint process.
                    </li>
                    </Typography>
                    <DialogActions>
                    <Button onClick={handleModalClose}>Close</Button>
                    </DialogActions>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '1rem'}}>
                        <CircularProgress />
                    </Box>                
                </>
                ) : txHash !== null ? (
                <Box>
                    <Typography gutterBottom>
                    Transaction submitted and in progress. Please do not close this tab.
                    </Typography>
                    <Container
                    style={{
                        padding: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    >
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '1rem'}}>
                        <CircularProgress />
                    </Box>                    
                    </Container>
                </Box>
                ) : null}
            </Box>
            ) : receipt ? (
            <>
                {openseaLink !== null ? (
                <DialogContentText>
                    Your NFT with Token ID {receipt.events.Transfer.returnValues.tokenId} successfully minted and your art assigned to it. 
                    <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '1rem'}}>
                        <Button href="##" variant="contained" sx={{ backgroundColor: '#fff28c', color: 'black' }}> View on Opensea</Button> 
                    </Container>
                    <DialogActions>
                    <Button onClick={handleModalClose}>Close</Button>
                    </DialogActions>
                </DialogContentText>
                ) : (
                <Typography>
                    Mint transaction{" "}
                    {receipt.status === true ? (
                    <>
                        <li>successful! Your Token ID is {receipt.events.Transfer.returnValues.tokenId}.</li> 
                        <li>Creating your NFT image. Please do not close this window or you may not be able to see this art on your NFT correctly.</li>
                        <Container
                        style={{
                            padding: "1rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        >
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: '1rem'}}>
                        <CircularProgress />
                    </Box>
                    </Container>
                    </>
                    ) : (
                    <>
                        failed.
                        <DialogActions>
                        <Button onClick={handleModalClose}>Close</Button>
                        </DialogActions>
                    </>
                    )}
                </Typography>
                )}
            </>
            ) : (
            <DialogContentText>
                Transaction was canceled.
                <DialogActions>
                <Button onClick={handleModalClose}>Close</Button>
                </DialogActions>
            </DialogContentText>
            )}
        </DialogContentText>
        </DialogContent>
    </Dialog>
    </>
    );
}



