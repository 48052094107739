import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import ImageList from './ImageList';
import Grid from '@mui/material/Unstable_Grid2';
import { Tree } from '@geist-ui/core';
import { Container } from '@mui/system';
import { Typography } from '@mui/material';


export default function FileSystemNavigator({walletAddress, isValid, allowLimit}) {

  const [foldersList, setFoldersList] = React.useState({
    BACKGROUNDS: [],
    SKINS: []
  });
  const [folderData, setFolderData] = React.useState({});

  // Handle folder click event
  const handleFolderClick = async (folderName, folderPath) => {
    try {
      const encodedFolderName = encodeURIComponent(folderName);
      const response = await axios.get(`https://m5cbvjq6l4.execute-api.us-east-1.amazonaws.com/v1/wallet_validation/${encodedFolderName}`, {
        params: {
          wallet_id: walletAddress, // assuming walletAddress is available in the scope of the component
          'folder_path': folderPath
        },
      });
      const data = response.data;
      const files = data.files;
      const folderFiles = files.map(file => {
        const url = file;
        const fileName = decodeURIComponent(url.split(`/${folderPath}/`)[1].split('/')[1].split('.')[0]);
        return { url, fileName };
      });
      setFolderData(() => ({
        [folderPath]: folderFiles.map(file => ({ 
          ...file}))
      }));
    } catch (error) {
      console.log(error);
    }
  }
  const loadFolders = async (folderPath) => {
    try {
      const response = await axios.get(`https://m5cbvjq6l4.execute-api.us-east-1.amazonaws.com/v1/wallet_validation/?`, {
        params: {
          wallet_id: walletAddress,
          'folder_path': folderPath
        },
      });
      const data = response.data;
      setFoldersList((prevFoldersList) => ({
        ...prevFoldersList,
        [folderPath]: data.folders?.map((folder) => ({ id: uuidv4(), title: folder })) || []
      }));
    } catch (error) {
      console.log(error);
    }
  }
  
  // Load the default folder data on initial load
  React.useEffect(() => {
    loadFolders("BACKGROUNDS");
    loadFolders("SKINS");
    handleFolderClick("Mushroom%20Dreams", "BACKGROUNDS");
    console.log(allowLimit.allowLimit, walletAddress);
  }, []);
  
  if (!isValid) {
    return null;
  }

  return (
    <>
      <Grid container style={{  display: 'flex', justifyContent: 'center', backgroundColor: 'rgba(255, 255, 255, 0.25)', color: 'white',borderRadius: '10px' }}>
        <Grid item xs={3} style={{ justifyContent: 'center', flex: 'center', padding: '1rem' }}>
        <Container sx={{ border: '1px solid black', borderRadius: '8px', padding: '1rem' }}>
          <Typography variant="h3" gutterBottom sx={{fontWeight: 'bold', fontSize:"32px" }}>
            Traits 
          </Typography>
        </Container>
          <Container> 
            <Tree initialExpand= "true" 
              style={{height: 900, overflowY: 'auto', overflowX: 'hidden', paddingTop: '1rem'}}>
              <Tree.Folder name="Backgrounds">
                {Array.isArray(foldersList.BACKGROUNDS) && foldersList.BACKGROUNDS.map((folder) => (
                  <Tree.File name={folder.title} onClick={() => handleFolderClick(folder.title, "BACKGROUNDS")} style={{ color: 'white' }}> 
                  </Tree.File>
                ))}      
              </Tree.Folder>
              <Tree.Folder name="Skins">
                {Array.isArray(foldersList.SKINS) && foldersList.SKINS.map((folder) => (
                  <Tree.File name={folder.title} onClick={() => handleFolderClick(folder.title, "SKINS")}> 
                  </Tree.File>
                ))}      
              </Tree.Folder>
            </Tree>
          </Container>
        </Grid>  
        <Grid item xs={9} style={{  display: 'flex', justifyContent: 'center' }}>
          {Object.keys(folderData).length > 0 && <ImageList folderData={folderData} walletAddress={walletAddress} allowLimit={allowLimit} />}
        </Grid>
      </Grid>
    </>
  );
}
